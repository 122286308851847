<i18n src="@/locales/dental_prosthesis.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="dental-prothesis">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{ $t('message_1') }}
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p2') }}
        </p>
        <ul>
          <li>{{ $t('article_p3_li_1') }}</li>
          <li>{{ $t('article_p3_li_2') }}</li>
          <li>{{ $t('article_p3_li_3') }}</li>
          <li>{{ $t('article_p3_li_4') }}</li>
        </ul>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_1') }}
        </h4>
        <p>
          {{ $t('article_p3_1') }}
        </p>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_2') }}
        </h4>
        <p>
          {{ $t('article_p3_2') }}
        </p>
        <p>
          <span v-html="$t('article_p3_3')" />
        </p>
        <p>
          <span v-html="$t('article_p3_4')" />
        </p>
        <p>
          <span v-html="$t('article_p3_5')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{ $t('article_p4') }}
        </p>
        <h4 class="has-text-primary is-size-6">
          {{ $t('h4_title_3') }}
        </h4>
        <p>
          {{ $t('article_p5_1') }}
        </p>
        <p>
          <span v-html="$t('article_p5_2')" />
        </p>
        <p>
          <span v-html="$t('article_p5_3')" />
        </p>
        <p>
          <span v-html="$t('article_p5_4')" />
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            <span v-html="$t('collapse_p4')" />
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalProsthesis',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [87, 65, 89, 83],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-care/prosthesis/stellite-prothese-amovible.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/prosthesis/prothese-dentaire-complete.jpg',
            position: 'right'
          }
        ]
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-care/prosthesis/H1-prothese-dentaire-amovible-d.jpeg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-care/prosthesis/H1-prothese-dentaire-amovible-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-care/prosthesis/H1-prothese-dentaire-amovible-m.jpg");
    }
  }
</style>
